.pbnjResults {
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}
.missingBacklinksWarning h4 {
  color: red;
}
.missingBacklinksWarning li {
  margin-top: 25px;
}
.missingBacklinksWarning li span {
  float: left;
  margin-right: 10px;
}

.actionBar {
  background-color: #eee;
  padding: 1rem;
}

.actionBar button {
    margin-right: 0.25rem;
}

.formWrapper {
  padding: 16px;
}

.loadingState {
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
}
.iterationCount {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
}